// extracted by mini-css-extract-plugin
export var container = "vacanciesContent-module--container--08907";
export var content = "vacanciesContent-module--content--58009";
export var contentItem = "vacanciesContent-module--contentItem--7ac39";
export var description = "vacanciesContent-module--description--cddd9";
export var heading = "vacanciesContent-module--heading--281e2";
export var image = "vacanciesContent-module--image--85a43";
export var imageContainer = "vacanciesContent-module--image-container--828ab";
export var itemContainer = "vacanciesContent-module--itemContainer--905ac";
export var menuContainer = "vacanciesContent-module--menuContainer--c5d2c";
export var notZoom = "vacanciesContent-module--notZoom--ddc79";
export var rightSide = "vacanciesContent-module--rightSide--c8245";
export var vacancyImages = "vacanciesContent-module--vacancy-images--e4b21";