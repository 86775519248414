import React, {useMemo, useState} from "react"
import Heading from "../../components/Elements/Heading"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import FsLightbox from "fslightbox-react"

import ScrollMenu from "../../components/ScrollMenu"

import {
  summerRest,
  summerRestFull,
  workWithUs1,
  workWithUs1Full,
  workWithUs2,
  workWithUs2Full,
  workWithUs3,
  workWithUs3Full
} from "../../assets/images"
import * as styles from "./vacanciesContent.module.css"
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

const VacanciesContent = ({ location }) => {
  const { t } = useTranslation()
  const infoContent = useMemo(() => [
    {
      title: t("Работа у нас"),
      text: t(
        "Если ты готов к новым вызовам, общению с ТОП-уровнем бренд команд ведущих производителей " +
          "товаров, готов учиться и стать уникальным специалистом по продажам в электронной коммерции, тогда ждем " +
          "тебя в нашей команде!"
      ),
      images: [
        {
          src: workWithUs1,
          fullSrc: workWithUs1Full
        },
        {
          src: workWithUs2,
          fullSrc: workWithUs2Full
        },
        {
          src: workWithUs3,
          fullSrc: workWithUs3Full
        },
      ],
      anchor: "work",
    },
    {
      title: t("летний офис"),
      text:
        "<strong>" +
        t(
          "У нас есть такая традиция: раз в год мы все вместе ездим работать в теплые края, " +
            "поближе к морю и солнцу"
        ) +
        ".</strong><br/>" +
        t(
          "В начале каждого года вместе с командой мы выбираем " +
            "направление, бронируем билеты, подбираем офис, в котором будет комфортно работать, и, конечно, место " +
            "проживания на месяц. Заряжаемся солнечными лучами, знакомимся с культурой новой страны и не забываем про " +
            "наших клиентов"
        ),
      images: [
        {
          src: summerRest,
          fullSrc: summerRestFull,
        }
      ],
      type: "right",
      anchor: "office",
    },
  ], [])

  const tabs = [
    {
      id: "vacancyMenu1",
      name: t("Работа у нас"),
      anchor: "jobs",
    },
    {
      id: "vacancyMenu2",
      name: t("Летний офис"),
      anchor: "office",
    },
    // {
    //   id: "vacancyMenu3",
    //   name: t("Вакансии"),
    //   anchor: "jobs",
    // },
  ]

  const [show, setShow] = useState(false)
  const [currentImage, updateCurrentImage] = useState("")

  const onChooseImage = image => {
    updateCurrentImage(image)
    setShow(true)
  }

  const closeImage = () => {
    updateCurrentImage("")
    setShow(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        <ScrollMenu
          tabs={tabs}
          hideArrows
          pageLink="vacancies"
          currentHash={location?.hash || "#work"}
        />
      </div>
      <div className={styles.content}>
        {infoContent.map((item, index) => (
          <div className={styles.itemContainer}>
            <a id={item.anchor} href="/#">
              {""}
            </a>
            <div
              key={index}
              className={cx(
                styles.contentItem,
                item?.type === "right" && styles.rightSide
              )}
            >
              <div className={styles.heading}>
                <Heading>{item.title}</Heading>
              </div>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
              <div className={cx(styles.vacancyImages, item?.type === "right" ? styles.notZoom : "")}>
                <Swiper spaceBetween={5} slidesPerView="auto" pagination={true} modules={[Pagination]}>
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className={styles.imageContainer}
                        onClick={() =>
                          item?.type !== "right" ? onChooseImage(image.fullSrc) : ""
                        }
                        aria-hidden="true"
                      >
                        <img className={styles.image} src={image.src} alt=""/>
                      </div>
                    </SwiperSlide>
                  ))}

                </Swiper>
              </div>

            </div>
          </div>
        ))}
        {show && (
          <FsLightbox
            openOnMount
            onClose={() => closeImage()}
            sources={[currentImage]}
          />
        )}
      </div>
    </div>
  )
}

export default VacanciesContent
